import * as React from "react"
import { Link } from "gatsby"
import Whitepaper from "../models/whitepaper"

const TeamWork = ({
  heading,
  description,
  btnText,
  btnLink,
  data,
  bottom,
  modal,
  pageContext: { translate },
}) => {
  return (
    <>
      {
        <div className="oppurtunity team-work">
          <div className="content">
            <div className="information">
              {(heading || btnText || description) && (
                <div className="flex-div">
                  {heading && <h2>{heading}</h2>}
                  {!!description && (
                    <p className="paragraph-p1 text-darker">{description}</p>
                  )}
                  {!bottom &&
                    !!btnText &&
                    (modal === "download" ? (
                      <Whitepaper
                        pageContext={{ translate }}
                        btnText={btnText}
                        styler="whitebtn"
                        title={translate.t?.download_the_whitepaper}
                        description={translate.t?.get_more_insights}
                        type="download"
                        submitBtnText={translate.t?.download_now}
                      />
                    ) : modal === "contact" ? (
                      <Whitepaper
                        pageContext={{ translate }}
                        btnText={btnText}
                        styler={btnText}
                        title={translate.t?.contact_form}
                        description={translate.t?.contact_form_description}
                        type="contact"
                        submitBtnText={translate.t?.submit}
                      />
                    ) : (
                      <Link to={btnLink}>
                        <button className="btndefault whitebtn">
                          {btnText}
                        </button>
                      </Link>
                    ))}
                </div>
              )}
              <div className="row">
                {data?.map((content, counter) => (
                  <div key={counter} className="col-6 col-lg-3">
                    <div className="box">
                      <div className="flex-group">
                        <img src={content.icon} alt="Team work" />
                        {!!content.title && <h3>{content.title}</h3>}
                      </div>
                      <p>{content.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              {bottom &&
                !!btnText &&
                (modal === "download" ? (
                  <Whitepaper
                    pageContext={{ translate }}
                    btnText={btnText}
                    styler="whitebtn"
                    title={translate.t?.download_the_whitepaper}
                    description={translate.t?.get_more_insights}
                    type="download"
                    submitBtnText={translate.t?.download_now}
                  />
                ) : (
                  <Link to={btnLink}>
                    <button className="btndefault whitebtn">{btnText}</button>
                  </Link>
                ))}
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default TeamWork
