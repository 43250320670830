import * as React from "react"

const Power = ({ pagename, title, description, img, description2, data }) => {
  return (
    <>
      {
        <div className="offer power">
          <div className="content ">
            <div className="wihte-layout">
              <div className="box1 box">
                <div className="padding-box">
                  <div className="box-content">
                    <div className="we-are">{pagename}</div>
                    <h2 className="heading-h2 semi text-darker">{title}</h2>
                    <p className="paragraph-p16 text-medium pb-2">
                      {description}
                    </p>
                    <p className="paragraph-p16 text-medium">{description2}</p>
                    <img src={img} alt="Power" />
                    <div className="greenline" />
                  </div>
                </div>
              </div>
              <div className="white-remaining" />
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default Power
