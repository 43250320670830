import * as React from "react"
import { Link } from "gatsby"

const Power = ({
  title,
  description,
  btnLink,
  btnText,
  data,
  data2,
  download,
}) => {
  return (
    <>
      {
        <div className="purpose">
          <div className="content ">
            <div className="purpose-work">
              {data.map((content, count) => (
                <div key={count} className="block">
                  <h3 className="heading-h24 text-medium semi">
                    {content.heading}
                  </h3>
                  <p className="paragraph-p16 text-medium">
                    {content.description}
                  </p>
                  <div className="vertical-line" />
                </div>
              ))}
            </div>
            <div className="all-purpose">
              <div className="information">
                <h2 className="heading-h2">{title}</h2>
                <p className="paragraph-p1">{description}</p>
                <Link to={btnLink}>
                  <button className="btndefault greenbtn">{btnText}</button>
                </Link>
              </div>
              <div className="list">
                {data2.map((content, count) => (
                  <div key={count} className="box">
                    <img src={content.icon} alt="Purpose" />
                    <p className="paragraph-p16">{content.description}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default Power
