import * as React from "react"
import { Link } from "gatsby"
import Whitepaper from "../models/whitepaper"
const Aboutus_Hero = ({
  pagename,
  title,
  btntext,
  btnLink,
  img,
  model,
  pageContext: { translate },
}) => {
  return (
    <>
      <div className="hero">
        <div className="content">
          <div className="information">
            <h1 className="heading-h1 semi text-darker">
              <p>
                {pagename} <span>{title}</span>
              </p>
            </h1>
            {model ? (
              <Whitepaper
                pageContext={{ translate }}
                btnText={btntext}
                styler="greenbtn"
                title={translate.t?.contact_form}
                description={translate.t?.contact_form_description}
                type="contact"
                submitBtnText={translate.t?.submit}
              />
            ) : (
              <Link to={btnLink}>
                <button className="btndefault greenbtn">{btntext}</button>
              </Link>
            )}
          </div>
          <img src={img} alt="About Us" />
        </div>
      </div>
    </>
  )
}

export default Aboutus_Hero
