import * as React from "react"
import AboutPage from "../components/about"
import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import languages from "../utils/enums/languages"

const About = ({ location, pageContext: { translate } }) => (
  <Layout
    secondary
    pageContext={{ translate }}
    path="aboutus"
    location={location}
    SEO={({ data }) => (
      <SEO
        title={data.t?.eraofwe_is_a_community}
        description={data.t?.eraofwe_believes_power_sharing}
        keywords={data.t?.keywords_aboutus}
        languages={[
          ...languages.map(l => {
            return {
              hrefLang: l || 'en',
              href: `${process.env.GATSBY_SITE_URL}${
                l ? "/" + l + "/" : "/"
              }aboutus/`,
            }
          }),
          {
            hrefLang: "x-default",
            href: `${process.env.GATSBY_SITE_URL}/aboutus/`,
          },
        ]}
      />
    )}
  >
    <AboutPage pageContext={{ translate }} />
  </Layout>
)

export default About
