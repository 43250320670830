import * as React from "react"
// import { useStaticQuery, graphql } from "gatsby"
// import { Link } from "gatsby"
import Join from "../roaster/join"
import Aboutus_Hero from "./hero"
import Power from "./power"
import Purpose from "./purpose"
// import Blog from "../community/blogs"
import Hero from "../roaster/hero"
import TeamWork from "../roaster/teamwork"

import heroimage from "../../assets/images/aboutus.png"
import power from "../../assets/images/power.jpg"
import p1 from "../../assets/images/icons/purpose1.svg"
import p3 from "../../assets/images/icons/purpose3.svg"
import p4 from "../../assets/images/icons/purpose4.svg"
import p5 from "../../assets/images/icons/purpose5.svg"
// import aboutarrow from "../../assets/images/icons/aboutarrow.svg"

const AboutPage = ({ pageContext: { translate } }) => {
  const dataPower = [
    {
      heading: translate.t?.our_mission,
      description: translate.t?.connect_empower_partners_chemistry,
    },
    {
      heading: translate.t?.our_vision,
      description: translate.t?.leader_global_ccc,
    },
  ]

  const dataPower1 = [
    {
      icon: p1,
      description: translate.t?.voice_coffee_fights,
    },
    {
      icon: p3,
      description: translate.t?.aim_solve_filtering_dilemma,
    },
    {
      icon: p4,
      description: translate.t?.successful_partnership_program,
    },
    {
      icon: p5,
      description: translate.t?.price_sys_multiple_opp,
    },
  ]

  const dataValue = [
    {
      title: translate.t?.anti_corruption_anti_bribery,
      description: translate.t?.voice_coffee_fights,
    },
    {
      title:  translate.t?.discrimination_against_individuals,
      description: translate.t?.discrimination_against_individuals_description,
    },
    {
      title: translate.t?.human_rights_labour,
      description:translate.t?.human_rights_labour_description,
    },
    {
      title: translate.t?.legal_ethical_compliance,
      description:translate.t?.legal_ethical_compliance_description,
    },
  ]

  const dataTeam = [
    {
      title: translate.t?.anti_corruption_anti_bribery,
      description: translate.t?.voice_coffee_rebalance,
    },
    {
      title: translate.t?.discrimination_against_individuals,
      description: translate.t?.discrimination_against_individuals_description,
    },
    {
      title: translate.t?.human_rights_labour,
      description: translate.t?.human_rights_labour_description,
    },
    {
      title: translate.t?.legal_ethical_compliance,
      description: translate.t?.legal_ethical_compliance_description,
    },
  ]

  // const nodes = useStaticQuery(
  //   graphql`
  //     query MyQueryAbout {
  //       allMarkdownRemark {
  //         nodes {
  //           frontmatter {
  //             author_image
  //             author_name
  //             blog_image
  //             blog_post
  //             category
  //             date
  //             slug
  //             title
  //             page_title
  //             meta_description
  //             thumbnail_summary
  //             thumbnail_image
  //           }
  //         }
  //       }
  //     }
  //   `
  // )
  // const allData = nodes.allMarkdownRemark.nodes

  return (
    <>
      <div className="about_page">
        <div className="content">
          <Aboutus_Hero
            pagename={translate.t?.era_of_we_is}
            title={translate.t?.tech_driven_solution_for_transparent}
            btntext={translate.t?.book_a_demo}
            btnLink=""
            img={heroimage}
            model={true}
            pageContext={{ translate }}
          />
          <div className="consumer_page about_us_power">
            <Power
              pagename={translate.t?.who_we_are_public}
              title={translate.t?.power_of_teamwork}
              description={translate.t?.eraofwe_believes_power_sharing}
              description2={translate.t?.we_want_secure_future}
              img={power}
            />
          </div>
          <Purpose
            data={dataPower}
            data2={dataPower1}
            title={translate.t?.purpose_of_eraofwe}
            description={translate.t?.eow_aims_to_increase}
            btnLink={`${
              translate.currentLang ? "/" + translate.currentLang + "/" : "/"
            }traceability/`}
            btnText={translate.t?.how_it_works}
          />
          <div className="secondhero">
            <Hero
              subTitle={translate.t?.change_begins_here}
              description={translate.t?.best_sustainability_practices_for}
              btntext={translate.t?.download_whitepaper}
              model="download"
              data={dataValue}
              pageContext={{ translate }}
            />
          </div>
          <div className="roaster_page">
            <TeamWork data={dataTeam} pageContext={{ translate }} />
          </div>
          <div className="community">
            {/* <h2 className="heading-h2">Updates and community news</h2>
          <Link className="solutionlink" to="/community_news">
            <span>Check out all our community news</span>
            <img src={aboutarrow} alt="About arrow" style={{ marginLeft: "15px" }} />
          </Link> */}
            {/* <div className="community_page">
            <div className="all-list">
              {allData.map((value, count) => {
                return (
                  count < 3 && (
                    <div
                      key={count}
                      className="col-12 col-md-6 col-lg-4 blog-wrapper"
                    >
                      <Blog
                        imgsec={value.frontmatter.thumbnail_image}
                        mobileimg={value.frontmatter.thumbnail_image}
                        len={65}
                        type={value.frontmatter.category}
                        heading={value.frontmatter.title}
                      />
                    </div>
                  )
                )
              })}
            </div>
          </div> */}
            <div className="joiner-second">
              <Join pageContext={{ translate }} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AboutPage
